
  .input-entry-box {
    /* background-color: white; */
    padding-top: 100px;
    max-width: 500px;
    justify-content: center;
    text-align: center;

  }

  .text-box {
    border-radius: 0;
    border-color: black;
    /* width: 100%; */
    /* height: 30px; */
    font-weight: 550;
    /* font-family: "DIN Bold",sans-serif;  */
    /* margin-bottom: 10px; */

  }
  .text-box:hover, 
  .text-box:active,
  .text-box:focus{
    box-shadow: none;
    border-color: black;
    opacity: 0.6;
  }
  .text-box::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 0.7; /*Firefox*/
  }

  .btn-ua {
    background-color: #275D38;
    border-color: #275D38;
    color: white;
    /* width: 220px; */
    /* margin: 0 30px; */
    width: 100%;
    font-weight: 600;
    border-radius: 0;
  } 
  
  .btn-ua:hover {
    background-color: #275D38;
    border-color: #275D38;
    
    color: white;
  }
  
  .btn-xl {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    /* font-family: "DIN Bold",sans-serif; */
    font-weight: 550;
    /* width: 50% */
  }

  .forgot-password {
    display: block;
    color:  #275D38;
    text-align: right;
  }

  .forgot-password:hover {
    text-decoration: underline;
    color:  #275D38;
  }


  .ua-link {
    display: inline;
    color:  #275D38;
    text-decoration: underline;
  }

  .ua-link:hover {
    color:  black;
  }
 

  .message-box {
    max-width: 500px;
    text-align: left;
    padding: 50px 15px;
  }
 .input-entry-box {
    /* background-color: white; */

    padding-top: 100px;
    max-width: 500px;
    justify-content: center;

  }

  .text-box {
    border-radius: 0;
    border-color: black;
    opacity: 0.3;

  }
  .text-box:hover, 
  .text-box:active,
  .text-box:focus{
    box-shadow: none;
    border-color: black;
    opacity: 0.6;
  }
  .text-box::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 0.7; /* Firefox */
  }

  .btn-ua {
    background-color: #275D38;
    border-color: #275D38;
    color: white;
    /* width: 220px; */
    /* margin: 0 30px; */
    width: 100%;
    font-weight: 600;
    border-radius: 0;
  } 
  
  .btn-ua:hover {
    background-color: #275D38;
    border-color: #275D38;
    
    color: white;
  }
  
  .btn-xl {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    /* font-family: "DIN Bold",sans-serif; */
    font-weight: 550;
    /* width: 50% */
  }

  .forgot-password {
    display: block;
    color:  #275D38;
    text-align: right;
  }

  .forgot-password:hover {
    text-decoration: underline;
    color:  #275D38;
  }


  .ua-link {
    display: inline;
    color:  #275D38;
    text-decoration: underline;
  }

  .ua-link:hover {
    color:  black;
  }
 

  .message-box {
    max-width: 500px;
    text-align: left;
    padding: 50px 15px;
  }
