.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100%;
  align-items: center;
  flex-direction: column;
}
.home-services {
  width: 100%;
  display: flex;
  padding: 10px;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: 80px;
  padding-left: 180px;
  padding-right: 180px;
  flex-direction: column;
  padding-bottom: 0px;
}
.home-heading-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text {
  text-align: center;
  margin-bottom: 40px;
}
.home-text001 {
  color: var(--dl-color-gray-700);
  align-self: flex-start;
  text-align: left;
  margin-bottom: 40px;
  padding-bottom: 0px;
}
.home-text002 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-text003 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-text004 {
  color: var(--dl-color-gray-black);
}
.home-text005 {
  color: #275d38;
  text-decoration: underline;
}
.home-text006 {
  color: var(--dl-color-gray-black);
}
.home-text007 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-text008 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-text009 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-text014 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-text015 {
  color: #275d38;
  text-decoration: underline;
}
.home-text016 {
  color: #275d38;
}
.home-text017 {
  color: var(--dl-color-gray-black);
}
.home-text018 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-text019 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-services1 {
  width: 100%;
  display: flex;
  padding: 0px;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: 80px;
  padding-left: 180px;
  padding-right: 180px;
  flex-direction: column;
  padding-bottom: 80px;
}
.home-heading-container1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text024 {
  text-align: center;
  margin-bottom: 40px;
}
.home-text025 {
  color: var(--dl-color-gray-black);
  align-self: flex-start;
  text-align: left;
  margin-bottom: x;
  padding-bottom: 0px;
}
.home-text026 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-text028 {
  color: #275d38;
  text-decoration: underline;
}
.home-text030 {
  color: #275d38;
}
.home-text032 {
  color: #275d38;
  text-decoration: underline;
}
.home-text033 {
  text-decoration: underline;
}
.home-text035 {
  color: #275d38;
  text-decoration: underline;
}
.home-text036 {
  color: #275d38;
}
.home-services2 {
  width: 100%;
  display: flex;
  padding: 0px;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: 0px;
  padding-left: 180px;
  padding-right: 180px;
  flex-direction: column;
  padding-bottom: 80px;
}
.home-heading-container2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text041 {
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text042 {
  color: var(--dl-color-gray-black);
  align-self: center;
  text-align: center;
  margin-bottom: 40px;
  padding-bottom: 0px;
}
.home-text043 {
  color: #275d38;
}
.home-text044 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-text048 {
  color: #275d38;
}
.home-text049 {
  color: #275d38;
}
.home-text050 {
  color: #275d38;
}
.home-text051 {
  color: #275d38;
  text-decoration: underline;
}
.home-br21 {
  color: #275d38;
  text-decoration: underline;
}
.home-text052 {
  color: #275d38;
}
.home-text053 {
  color: #275d38;
  text-decoration: underline;
}
.home-text054 {
  text-decoration: underline;
}
.home-services3 {
  width: 100%;
  display: flex;
  padding: 0px;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: 0px;
  padding-left: 180px;
  padding-right: 180px;
  flex-direction: column;
  padding-bottom: 80px;
}
.home-heading-container3 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text056 {
  text-align: center;
  margin-bottom: 40px;
}
.home-text057 {
  color: var(--dl-color-gray-black);
  align-self: flex-start;
  text-align: left;
  margin-bottom: 40px;
  padding-bottom: 0px;
}
.home-text058 {
  color: #275d38;
  text-decoration: underline;
}
.home-text059 {
  color: #275d38;
}
.home-team {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: 0px;
  padding-left: 180px;
  padding-right: 180px;
  flex-direction: column;
  padding-bottom: 80px;
}
.home-heading-container4 {
  width: 65%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-text084 {
  text-align: center;
  margin-bottom: 40px;
}
.home-text087 {
  color: var(--dl-color-gray-black);
  text-align: center;
  margin-bottom: 40px;
  padding-bottom: 40px;
}
.home-text089 {
  color: #275d38;
  text-decoration: underline;
}
.home-text091 {
  color: #275d38;
  text-decoration: underline;
}
.home-text093 {
  color: #275d38;
  text-decoration: underline;
}
.home-cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-team-card {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: column;
}
.home-avatar-container {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon {
  width: 48px;
  height: 48px;
}
.home-name {
  margin-bottom: var(--dl-space-space-unit);
}
.home-position {
  color: var(--dl-color-gray-black);
}
.home-team-card1 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: column;
}
.home-avatar-container1 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon2 {
  width: 48px;
  height: 48px;
}
.home-name1 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-position1 {
  color: var(--dl-color-gray-700);
}
.home-text101 {
  color: var(--dl-color-gray-black);
}
.home-team-card2 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: column;
}
.home-avatar-container2 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon4 {
  width: 48px;
  height: 48px;
}
.home-name2 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-position2 {
  color: var(--dl-color-gray-700);
}
.home-text105 {
  color: var(--dl-color-gray-black);
}
.home-team-card3 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: column;
}
.home-avatar-container3 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon6 {
  width: 48px;
  height: 48px;
}
.home-name3 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-position3 {
  color: var(--dl-color-gray-700);
}
.home-text109 {
  color: var(--dl-color-gray-black);
}
@media(max-width: 991px) {
  .home-cards-container {
    justify-content: center;
  }
}
@media(max-width: 767px) {
  .home-heading-container {
    width: 100%;
  }
  .home-heading-container1 {
    width: 100%;
  }
  .home-heading-container2 {
    width: 100%;
  }
  .home-heading-container3 {
    width: 100%;
  }
  .home-heading-container4 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .home-services {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-services1 {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-services2 {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-services3 {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-team {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-text084 {
    text-align: center;
  }
}
