@import url("https://fonts.googleapis.com/css2?family=Sora&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
	/*font-family: 'Sora', sans-serif;*/
	font-family: Roboto, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans",
		"Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
		"Noto Color Emoji";
	/* font-family: Roboto; */
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: #f1f1f1;
	width: 10px;
	border-radius: 5px;
}

::-webkit-scrollbar-thumb {
	background-color: #888;
	border-radius: 5px;
}
