@media all and (min-width: 1000px){ 

.uabanner {
  display: flex;
  height: 10vh;
  width: 30vh;
  max-height: 100px;
  margin: auto;
  position: relative;
  left: 2%;
}

 
 .banner {
    min-height: 18vh;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #275d38;
  }

  .banner-title {
    flex: 1;
    margin: auto;
  }

  .title {
    font-size: 40px;
    font-weight: 600;
    color: white;
    display: flex;
    text-align: center;
    justify-content: center;
    position: relative;
    right: 1%;
    

    
  }

  .ccid-text {
    margin-bottom: 2px;
    width: 100%;
  }

  .banner-loggedin{
    width: 150px;
    overflow: hidden;
    position: relative;
    right: 4%;
    margin:auto;
    color: white;
    font-weight: 800;

  }
 
  .banner-loggedinplaceholder{
    width: 200px;
    position: relative;
    right: 5%;
    margin:auto;
    color: white;
    font-weight: 800;
    visibility: hidden;

  }
}

@media all and (max-width: 1000px){
  .uabanner {
    display: flex;
    height: 100px;
    width: 200px;
    max-height: 100px;
    margin: auto;
    position: relative;
    left: 2%;
  }
  
   
   .banner {
      min-height: 18vh;
      width: 100%;
      display: flex;
      background-color: #275d38;
    }
  
    .banner-title {
      flex: 1;
      margin: auto;
    }
  
    .title {
      font-size: 26px;
      font-weight: 500;
      color: white;
      display: flex;
      text-align: center;
      justify-content: center;
      position: relative;
      right: -4%;
      
  
      
    }

    .logout-button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      right: -11%;
      margin: auto;
      font-weight: 800;
      font-size: 16px;
      width: 100px;
      height: 30px;
    }

    .ccid-text{
      display: inline;
      position: relative;
      right: -35%;
      bottom: 1px;
      margin: auto;
      font-weight: 700;
      font-size: 14px;
      width: 100px;
      height: 30px;
    }
  
    .banner-loggedin{
      width: 200px;
      position: relative;
      right: 5%;
      margin:auto;
      color: white;
      font-weight: 800;
  
    }
   
    .banner-loggedinplaceholder{
      width: 200px;
      position: relative;
      right: 5%;
      margin:auto;
      color: white;
      font-weight: 800;
      visibility: hidden;
  
    }
  
}

@media all and (max-width: 700px){
  .uabanner {
    display: flex;
    height: 100px;
    width: 150px;
    max-height: 100px;
    margin: auto;
    position: relative;
    left: 2%;
    top: 8px;
  }

  .title {
    font-size: 0px;
    font-weight: 500;
    position: absolute;
    color: white;
    display: flex;
    text-align: center;
    justify-content: center;
    top: 4px;
    left: -19px;
  }

    .banner-loggedin{
      width: 200px;
      position: relative;
      right: 0%;
      top: 8px;
      margin:auto;
      color: white;
      font-weight: 800;
  
    }
   
    .banner-loggedinplaceholder{
      width: 200px;
      position: relative;
      right: 5%;
      margin:auto;
      color: white;
      font-weight: 800;
      visibility: hidden;
  
    }
    

    
  }


  

