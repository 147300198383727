


.container {
    margin: 0;
    background-color: #DEDEDE;
    width: 100%;
  }
  
  .main {
    min-height: 82vh;
    padding: 4rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: large; 
  }

  .hyperlinktext {
    color: #275d38;
    font-weight:700;
  }
  .login-title {
    font-size: xx-large;
    font-weight: 400;
    text-align: center;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .pulse {
    animation: pulse 1s infinite;
  }


  .servicebuttonlayout {
    display: flex;
    gap: 10px;
    padding: 20px;
    width: 100%;
    justify-content: center;
    align-content: center;
  }

  .buttontext {
    font-size: large;
}

  
  .formgrid {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 90%;
    margin-top: 20px;
  }

  .gpscorgrid {
    display: flex;
    flex-direction: column;
    width: 100%;
    
  }
  .individualquestionitem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .assign2questiongroup {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;

  }

  .notAllMultiple {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .questionitemgroup {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:20px;
    width: 100%;
  }

  .uploadinstructions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 50px;
    gap: 20px
  }
  
  
  
  .descriptiontext {
    font-size: larger;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: -5%;

}

  .newordisplaytext {
    font-size: x-large;
    text-align: center;
    margin-bottom: 40px;
    margin-top: -80px;
    padding: 5%;

  }

  .successtext {
    font-size: x-large;
    text-align: center;

    padding: 5%;

  }
  .question-text {
    color: black;
    margin-left: 20px;
    font-size: large;
    text-align: center;
    display: inline;
  }
  .assign2dropdowns {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    flex-wrap:wrap;
  }
  .assign2classdropdown {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }


  .questionarrange{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .comments-text {
    margin-top: 20px;
    font-size: large;
  }

  
  .uploadbuttongap {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  }

  .examWeightsList {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 105%;
    margin-left: -2.5%;
  }

  .classandswitch {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-top: 20px;
  }

  

  /*For dept and address inputs, speedcode offset by circularprogress  */
  .inputdiv {
    margin-bottom: -40px;
  }

  @media all and (max-width: 1000px) {
    .buttonlayout {
      padding: 20px;
    }
  }
  
 

  @media all and (max-width: 700px) {
    .mainbox {
      width: 100%;
    }
    .grid {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 90%;
      margin-top: 20px;
    }

    .questionarrange
    {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
    .buttonlayout {
      width: 100%;
      display: flex;
      flex-direction: row;

    }
    .descriptiontext {
      font-size: larger;
      text-align: center;
    }
    .classandswitch {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-top: 40px;
      width: 100%;
      flex-wrap: wrap;
    }
    .textfieldcomponent {
      display: flex;
      flex-direction: column;
      gap: 4px;
      color: black;
      font-size: large;
      text-align: center;
      align-items: center;
      margin-bottom: 20px;
      margin-top: -20px;
    }
    .question-text {
      color: black;
      font-size: large;
      text-align: center;
      margin-left: 10px;
      align-self: baseline;
    }
    .questionarrange {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 60px;
      margin-top: 20px;
      column-gap: 60px;
    }
    .buttontext {
      font-size: large;
    }

    .grid {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 90%;
      margin-top: 20px;
    }
    .speedcodemodal {
      margin-top: -80px;

    }
    .inputdiv {
      margin-bottom: -40px;
    }
    .individualquestionitem {
      margin-bottom: 20px;
    }
    .speedcodebutton
    {
      display:none;
    }
  
  }

  @media all and (max-width: 500px) {
    .mainbox {
      width: 100%;
    }
    .buttonlayout {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
    }
    .descriptiontext {
      font-size: larger;
      text-align: center;
    }
    .speedcodebutton
    {
      display:none;
    }
    .classandswitch {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-top: 60px;
      width: 100%;
      flex-wrap: wrap;
    }
    .textfieldcomponent {
      display: flex;
      flex-direction: column;
      gap: 4px;
      color: black;
      font-size: large;
      text-align: center;
      margin-bottom: 20px;
      margin-top: -20px;
    }
    .question-text {
      color: black;
      font-size: large;
      text-align: center;
      margin-left: 10px;
      align-self: baseline;
    }
    .questionarrange {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .buttontext {
      font-size: large;
      
    }
    .grid {
      width: 100%;


    } 
    .gpscorgrid {
      width: 100%;
      ;
      
    }
    .individualquestionitem {
      margin-bottom: 40px;

    }
  
  
  }

  