.footer {
    bottom: 0;
    width: 100%;
    
    color:white;
  }

  .footer-text{
      color: white;
  }

  @media all and (min-width: 1000px){ 
  }

@media all and (min-width: 700px){ 
    }
@media all and (min-width: 500px){ }